import React from "react"
import "./Tutoring.scss"
import Typer from './typer/typer'
import Contact from '../contact/Contact'
import AniLink from "gatsby-plugin-transition-link/AniLink";

function Tutoring() {
  return (
    <div className="tutoring-wrapper">
      <div className="top-background">
        <div className="left-slider">
          <AniLink swipe direction="left" to="/">
            Go back &gt;
          </AniLink>
        </div>
        <div className="tutoring-title">
          <div className="face-pic"></div>
          <div className="title-block">
            <h1>Need tutoring?</h1>
              <h3>I teach <Typer></Typer></h3>
          </div>
        </div>
      </div>

      <div className="services-wrapper">
        <div className="service-panel">
          1 on 1 lessons
          <ul>
            <li className="icon fa-check"> Use your own textbook</li>
            <li className="icon fa-check"> Personalised approach</li>
            <li className="icon fa-check"> Support for more questions after</li>
          </ul></div>
        <div className="service-panel">
          Assignments
        <ul>
            <li className="icon fa-check"> Guiding when you make it</li>
            <li className="icon fa-check"> Delivering finished assignment</li>
            <li className="icon fa-check"> Fixing non-working attempt</li>
          </ul></div>
        <div className="service-panel">
          Exam preparations
        <ul>
            <li className="icon fa-check"> Speedcourse from scratch</li>
            <li className="icon fa-check"> Guiding through example exams</li>
            <li className="icon fa-check"> Reviewing failed exam</li>
          </ul></div>
      </div>
      <div className="contact-form">
        <h1>Contact</h1>
      <Contact></Contact>
      </div>
    </div>
  )
}

export default Tutoring
