
import "./typer.scss"
import Typist from 'react-typist';
import React, { useState, useEffect } from "react";



function Typer() {
  const [count, setCount] = useState(1);
  useEffect(() => {
    setCount(1);
  }, [count]);
  return (
    <Typist key={count} avgTypingDelay={50} onTypingDone={() => setCount(0)}>
      <span>Programming</span>
      <Typist.Backspace count={'Programming'.length} delay={3000} />
      <span>HTML & CSS</span>
      <Typist.Backspace count={'HTML & CSS'.length} delay={3000} />
      <span>Javascript</span>
      <Typist.Backspace count={'Javascript'.length} delay={3000} />
      <span>Angular</span>
      <Typist.Backspace count={'Angular 4+'.length} delay={3000} />
      <span>Java</span>
      <Typist.Backspace count={'Java'.length} delay={3000} />

    </Typist>
  )
}

export default Typer
