import React from "react"

function Contact() {
    return (
        <div>
            <form name="contact" method="post" data-netlify="true">
                <input type="hidden" name="form-name" value="contact"/>
                <div className="row">
                  <div className="col-6 col-12-mobile">
                    <input type="text" name="name" placeholder="Name" />
                  </div>
                  <div className="col-6 col-12-mobile">
                    <input type="text" name="email" placeholder="Email" />
                  </div>
                  <div className="col-12">
                    <textarea name="message" placeholder="Message" />
                  </div>
                  <div className="col-12">
                    <button id="contact-submit" type="submit">Send Message</button>
                  </div>
                </div>
              </form>
        </div>)
}
export default Contact;
